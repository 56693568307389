import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { Divider } from 'primeng/divider';

@Component({
  selector: 'lib-rail-divider',
  imports: [CommonModule, TooltipModule, TranslateModule, Divider],
  template: ` <p-divider [styleClass]="'app-divider'"></p-divider> `,
  styles: `
    :root {
      .dev :host .app-divider::before,
      .test :host .app-divider::before {
        border-width: 1px;
        border-radius: 4px;
        border-color: var(--p-divider-border-color);
      }

      .dev :host .app-divider {
        --p-divider-border-color: orange;
      }

      .test :host .app-divider {
        --p-divider-border-color: hotpink;
      }
    }
  `,
})
export class RailDividerComponent {}
