import { CommonModule } from '@angular/common';
import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { ApplicationStorageService } from '@logic-suite/shared/storage';
import { SvgIconComponent, SvgIcons } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { RouterService } from '@suite/router';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';
import { LoaderModule } from '@logic-suite/shared/components/loader';
import { RailDividerComponent, RailLinkComponent, RailLinkListComponent } from '@suite/rail/ui';
import { HasPrivilegesDirective } from '@suite/acl-data-access';
import { UserService } from '@suite/user-data-access';
import { CustomerService } from '../../../../../../../apps/suite/src/app/shared/access';
import { PrivilegeAccessStrategy, PrivilegeConfig } from '@suite/privileges-models';
import { PrivilegesService } from '@suite/privileges-data-access';

interface NavLink {
  path: string;
  tooltip: string;
  icon: SvgIcons;
  privilegeConfig: PrivilegeConfig;
}

@Component({
  selector: 'lib-rail',
  imports: [
    CommonModule,
    DividerModule,
    ButtonModule,
    AvatarModule,
    TooltipModule,
    RouterLink,
    TranslateModule,
    LoaderModule,
    RailLinkComponent,
    SvgIconComponent,
    RailLinkListComponent,
    RailDividerComponent,
    HasPrivilegesDirective,
  ],
  template: `
    <aside class="bg-surface-900 flex h-full w-16 flex-col items-center justify-between py-4">
      <div>
        <div class="relative flex size-12 items-center justify-center">
          <lib-loader class="absolute size-12 text-white" [useGlobalLoader]="true" [strokeWidth]="8"></lib-loader>

          <lib-rail-link [active]="false" [routerLink]="['/energy']">
            <svg-icon [key]="'noova-logo'" size="xxl"></svg-icon>
          </lib-rail-link>
        </div>

        <lib-rail-divider></lib-rail-divider>

        <!-- Customer select -->
        @if (canSelectCustomer()) {
          <lib-rail-link
            [active]="isActive('/select-customer')"
            [routerLink]="['/select-customer']"
            [tooltip]="selectCustomerTooltip()">
            <svg-icon [key]="'switch-arrows'"></svg-icon>
          </lib-rail-link>

          <p-divider></p-divider>
        }

        <!-- Primary nav -->
        <lib-rail-link-list>
          @for (link of primaryNavLinks(); track link.path) {
            <lib-rail-link
              *libHasPrivileges="link.privilegeConfig"
              [active]="isActive(link.path)"
              [routerLink]="[link.path]"
              [tooltip]="link.tooltip">
              <svg-icon [key]="link.icon"></svg-icon>
            </lib-rail-link>
          }
        </lib-rail-link-list>
      </div>

      <div>
        <!-- Secondary nav -->
        <lib-rail-link-list>
          @for (link of secondaryNavLinks(); track link.path) {
            <lib-rail-link
              *libHasPrivileges="link.privilegeConfig"
              [active]="isActive(link.path)"
              [routerLink]="[link.path]"
              [tooltip]="link.tooltip">
              <svg-icon [key]="link.icon"></svg-icon>
            </lib-rail-link>
          }
        </lib-rail-link-list>

        <p-divider></p-divider>

        <lib-rail-link
          [active]="isActive('/profile')"
          [routerLink]="['/profile']"
          [tooltip]="'Your profile' | translate">
          <p-avatar shape="circle" [label]="avatarInitials()"></p-avatar>
        </lib-rail-link>
      </div>
    </aside>
  `,
  styles: `
    ::ng-deep .loader.overlay {
      background: color-mix(in srgb, var(--p-surface-900) calc(100% * var(--tw-bg-opacity, 1)), transparent);
    }
  `,
})
export class RailComponent implements OnInit {
  private readonly applicationStorageService = inject(ApplicationStorageService);
  private readonly customerService = inject(CustomerService);
  private readonly routerService = inject(RouterService);
  private readonly userService = inject(UserService);
  private readonly privilegesService = inject(PrivilegesService);

  currentUser = computed(() => this.userService.userResource.value());
  currentCustomer = toSignal(this.customerService.selectedCustomer$);
  canSelectCustomer = signal(this.applicationStorageService.getItem('user.hasMultipleCustomers') ?? false);
  selectCustomerTooltip = computed(() => {
    const currentCustomer = this.currentCustomer();
    if (!currentCustomer) {
      return 'Unknown customer';
    }

    return `${currentCustomer.customerID} - ${currentCustomer.name}`;
  });

  // Build primary nav links based on Application `hasAccess` flag
  primaryNavLinks = computed<NavLink[]>(() => [
    {
      privilegeConfig: {
        privileges: [
          this.privilegesService.energy(),
          this.privilegesService.environment(),
          this.privilegesService.financial(),
        ],
        accessStrategy: PrivilegeAccessStrategy.anyNode,
      },
      path: '/energy',
      tooltip: 'Energy',
      icon: 'energy',
    },
    {
      privilegeConfig: {
        privileges: [this.privilegesService.advise()],
        accessStrategy: PrivilegeAccessStrategy.rootOnly,
      },
      path: '/advise',
      tooltip: 'Advise',
      icon: 'bell',
    },
    {
      privilegeConfig: {
        privileges: [this.privilegesService.sense()],
        accessStrategy: PrivilegeAccessStrategy.anyNode,
      },
      path: '/sense',
      tooltip: 'Sense',
      icon: 'chip',
    },
    {
      privilegeConfig: {
        privileges: [this.privilegesService.facility()],
        accessStrategy: PrivilegeAccessStrategy.anyNode,
      },
      path: '/facility',
      tooltip: 'Facility',
      icon: 'flow',
    },
    {
      privilegeConfig: {
        privileges: [this.privilegesService.financial()],
        accessStrategy: PrivilegeAccessStrategy.rootOnly,
      },
      path: '/financial',
      tooltip: 'Financial',
      icon: 'money',
    },
    {
      privilegeConfig: {
        privileges: [this.privilegesService.energy()],
        accessStrategy: PrivilegeAccessStrategy.anyNodeOfTypes,
        resources: [
          {
            type: 'customer',
            id: '',
          },
          {
            type: 'assetGroup',
            id: '',
          },
          {
            type: 'asset',
            id: '',
          },
        ],
      },
      path: '/asset-log',
      tooltip: 'Asset log',
      icon: 'clipboard',
    },
  ]);

  secondaryNavLinks = computed<NavLink[]>(() => [
    {
      privilegeConfig: {
        privileges: [this.privilegesService.assetConfig()],
        accessStrategy: PrivilegeAccessStrategy.anyNode,
      },
      path: '/config',
      tooltip: 'Configuration',
      icon: 'cog',
    },
    {
      privilegeConfig: {
        privileges: [this.privilegesService.aclConfig(), this.privilegesService.aclAssignment()],
        privilegeMatchStrategy: 'OR',
        accessStrategy: PrivilegeAccessStrategy.rootOnly,
      },
      path: '/acl',
      tooltip: 'ACL',
      icon: 'acl',
    },
    {
      privilegeConfig: {
        privileges: [this.privilegesService.subscriptions()],
        accessStrategy: PrivilegeAccessStrategy.rootOnly,
      },
      path: '/users',
      tooltip: 'Subscriptions',
      icon: 'users',
    },
  ]);

  ngOnInit(): void {
    // Hide or display select customer link based on users customer list length
    this.customerService.getCustomerList().subscribe((customers) => {
      this.applicationStorageService.setItem('user.hasMultipleCustomers', customers.length > 1);
      this.canSelectCustomer.set(customers.length > 1);
    });
  }

  isActive(path: string): boolean {
    return this.routerService.currentUrl().startsWith(path.slice(0, -1)) ?? false;
  }

  // TODO(bjhandeland): Move this code to the User class when it is available.
  /**
   * Generate initials for user avatar.
   *
   * First tries to get initials from first and last name.
   * If only one of them is present, uses first two chars of that.
   * Then tries to get initials from first part of email before @ symbol.
   * If all else fails, uses first two chars of email.
   */
  avatarInitials = computed(() => {
    const user = this.currentUser();
    if (!user) {
      return '';
    }

    const firstName = user.firstName?.trim();
    const lastName = user.lastName?.trim();
    if (firstName && lastName) {
      return `${firstName[0]}${lastName[0]}`.toUpperCase();
    }

    if (firstName) {
      return firstName.slice(0, 2).toUpperCase();
    }

    if (lastName) {
      return lastName.slice(0, 2).toUpperCase();
    }

    const email = user.email?.trim() ?? '';
    const username = email.split('@')[0];
    if (username) {
      return username.slice(0, 2).toUpperCase();
    }

    return email.slice(0, 2).toUpperCase();
  });
}
