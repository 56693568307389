import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'lib-rail-link-list',
  imports: [CommonModule, TooltipModule, TranslateModule],
  template: `
    <nav class="flex flex-col items-center gap-1">
      <ng-content></ng-content>
    </nav>
  `,
})
export class RailLinkListComponent {}
