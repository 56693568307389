import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { QueryParamsHandling, RouterLink } from '@angular/router';

@Component({
  selector: 'lib-rail-link',
  imports: [CommonModule, TooltipModule, TranslateModule, RouterLink],
  template: `
    <a
      class="hover:bg-surface-700 flex size-12 items-center justify-center rounded text-white transition-all duration-200 hover:no-underline"
      [class.bg-surface-700]="active()"
      [routerLink]="routerLink()"
      [queryParamsHandling]="queryParamsHandling() || 'merge'"
      [pTooltip]="tooltip() | translate"
      [showDelay]="500">
      <ng-content></ng-content>
    </a>
  `,
})
export class RailLinkComponent {
  active = input.required<boolean>();
  routerLink = input.required<string[]>();
  tooltip = input<string>('');
  queryParamsHandling = input<QueryParamsHandling>();
}
